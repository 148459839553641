/* Reset and base styles  */

body {
  background-color: #f5f5f5;
}

p,
span {
  font-size: 18px !important;
}

* {
  padding: 0;
  margin: 0;
  border: none;
  font-size: 16px;
  font-weight: 400;
  font-family: "Assistant";
  font-size: 16px;
  border-radius: 0 !important;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

:focus,
:active {
  /*outline: none;*/
}

a:focus,
a:active {
  /* outline: none;*/
}

/* Links */

a,
a:link,
a:visited {
  transition: 0.3s all;
  text-decoration: none;
  /* display: inline-block; */
}

a:hover {
  color: #ff4f00 !important;
  /* color: inherit; */
  text-decoration: none;
}

/* Common */

aside,
nav,
footer,
header,
section,
main {
  display: block;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-size: inherit;
  font-weight: inherit;
}

ul,
ul li {
  list-style: none;
}

img {
  vertical-align: top;
}

img,
svg {
  max-width: 100%;
  height: auto;
}

/* Form */

input,
textarea,
button,
select {
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  background-color: transparent;
}

input::-ms-clear {
  display: none;
}

button,
input[type="submit"] {
  display: inline-block;
  box-shadow: none;
  background-color: transparent;
  background: none;
  cursor: pointer;
}

input:focus,
input:active,
button:focus,
button:active {
  outline: none;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

label {
  cursor: pointer;
}

legend {
  display: block;
}

.active {
  color: #ff4f00 !important;
}

.page {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.no-scroll {
  overflow: hidden;
}

.container {
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  padding: 0 128px;
}

.show-and-hide {
  width: 90px;
  height: 30px;
  text-align: center;
  background: #d4d4d4;
}

.show-and-hide p {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
}

@media screen and (max-width: 800px) {
  p,
  span {
    font-size: 16px !important;
  }
}

@media screen and (max-width: 1600px) {
  .container {
    max-width: 1366px;
    padding: 0 64px;
  }
}

@media screen and (max-width: 1366px) {
  .container {
    max-width: 1024px;
    padding: 0 32px;
  }
}

@media screen and (max-width: 1024px) {
  .container {
    max-width: 768px;
    padding: 0 16px;
  }
}

@media screen and (max-width: 768px) {
  .container {
    max-width: 100%;
    padding: 0 16px;
  }
}
