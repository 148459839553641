.ourStoryContainer {
    position: relative;
    background-color: #4d4d4d;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 32px 0 42px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: 100px 0 100px 0;
    width: 100%;
}
.ourStoryText {
    margin: 0 auto 60px auto;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #fff;
    max-width: 688px;
}

@media screen and (max-width: 800px) {
    .ourStoryContainer {
        margin: 80px 0 80px 0;
    }
}
