.cardList {
    display: flex;
    gap: 32px;
    justify-content: center;
}

@media (max-width: 800px) {
    .cardList {
        flex-direction: column;
        align-items: center;
        gap: 16px;
    }
}