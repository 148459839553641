.menu {
  display: flex;
  align-items: center;
  font-size: 22px;
  line-height: 28px;
}

.navList {
  display: flex;
  gap: 40px;
}

.navList li a {
  width: 100px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

@media (max-width: 837px) {
  .navList {
    display: flex;
    gap: 40px;
  }
}

@media (max-width: 800px) {
  .menu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: fixed;
    left: -100%;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100vh;
    background-color: #f5f5f5;
    z-index: 10;
    transition: left 1s;
  }

  .active {
    left: 0;
    z-index: 998;
  }

  .menu a {
    font-size: 28px;
  }
}
