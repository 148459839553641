.button {
    width: 254px;
    height: 56px;
    background: #FF4F00;
    color: white;
    transition: .3s all;
    border: 1px solid #FF4F00;
    line-height: 56px;
    font-weight: 500;
    font-size: 18px;
    display: inline-block;
}

.button:hover {
    color: #FF4F00;
    background: #f5f5f5;
}

.buttonText {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    text-align: center;
    font-size: 18px;
    font-weight: 500;
}

@media screen and (max-width: 837px) {
    .button {
        width: 208px;
    }
}

@media screen and (max-width: 800px) {
    .button {
        width: 156px;
        height: 50px;
    }

    .buttonText {
        font-size: 16px;
    }
}