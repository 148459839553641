.blockTitle {
    font-style: normal;
    font-weight: 600;
    font-size: 44px;
    line-height: 58px;
    color: #000000;
}

@media screen and (max-width: 837px) {
    .blockTitle {
        font-weight: 600;
        font-size: 36px;
    }
}

@media screen and (max-width: 800px) {
    .blockTitle {
        font-weight: 600;
        font-size: 36px;
    }
}

.titleLine {
    width: 100px;
    height: 4px;
    background-color: #FF4F00;
}

@media screen and (max-width: 837px) {
    .titleLine {
        width: 68px;
    }
}

.titleBox {
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: center;
}

.blockText {
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
}
