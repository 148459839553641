.formLabel {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #ff4f00;
  margin-bottom: 22px;
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.formError {
  color: red;
  font-size: 16px;
  margin-top: 5px;
}

.formInput {
  padding: 12px 16px;
  border: 1px solid rgba(58, 62, 62, 0.5);
  margin-top: 2px;
  color: #000;
}

.formInputCheckbox {
  width: 16px;
  height: 16px;
}

.submitButton {
  width: 254px;
  height: 56px;
  color: white;
  background-color: #ff4f00 !important;
  transition: 0.3s all;
  border: 1px solid #ff4f00;
  font-size: 18px;
  font-weight: 500;
}

.submitButton:hover {
  color: #ff4f00;
  background-color: #f5f5f5 !important;
}

.checkboxText {
}

.form {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 808px;
}

@media screen and (max-width: 837px) {
  .submitButton {
    width: 208px;
  }
}

.reviewsModalDoneContainer {
  display: flex;
  flex-direction: column;
  gap: 18px;
  text-align: center;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 800px) {
  .form {
    width: 100%;
  }

  .submitButton {
    width: 156px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
  }

  .submitButtonContainer {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .reviewsModalContainer {
    margin-top: 40px;
  }
}

.lastInput {
  margin-bottom: 20px;
}

.formMessage {
  border: 1px solid rgba(58, 62, 62, 0.5);
  margin-top: 2px;
  color: #000;
  padding: 12px 16px;
}

.checkboxGroup {
  display: flex;
  flex-direction: column;
  margin-bottom: 22px;
}

.formCheckboxLabel {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #ff4f00;
  display: flex;
  gap: 8px;
  align-items: center;
}

.checkboxGroupTitle {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #ff4f00;
  margin-bottom: 5px;
}

.reviewsModalDoneText {
  font-weight: 600;
  font-size: 44px;
  line-height: 58px;
  text-align: center;
  color: #000000;
}

.reviewsModalContainer {
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.reviewsModal {
  margin-top: 30px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 30px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
  max-width: 568px;
  width: 100%;
}

@media screen and (max-width: 660px) {
  .reviewsModalDoneContainer {
    gap: 10px;
  }

  .reviewsModalDoneText {
    font-size: 32px;
  }

  .reviewsModal {
    width: 350px;
    padding: 10px;
  }
}
