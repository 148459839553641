.reviewsList {
    display: grid;
    grid-gap: 32px;
    grid-template-columns: repeat(4, 0fr);
    text-align: center;
    justify-content: center;
    justify-items: center;
}

@media only screen and (max-width: 1450px) {
    .reviewsList {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media only screen and (max-width: 1100px) {
    .reviewsList {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media only screen and (max-width: 800px) {
    .reviewsList {
        grid-template-columns: repeat(1, 1fr);
    }
}