.imageModalContainer {
    cursor: pointer;
}

.projectImage {
    width: 100%;
    height: auto;
}

.projectModal {
    margin-top: 30px;
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -60%);
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
    max-width: 800px;
    width: 100%;
    justify-content: center;
    display: flex;
    max-height: 80%;
}

.projectModalImage {
    width: 100%;
    object-fit: cover;
}

.projectModalOverlay {
    position: fixed;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.75);
    cursor: pointer;
}

@media screen and (max-width: 1100px) {
    .projectModal {
        max-width: 100%;
    }
}

@media screen and (max-width: 800px) {
    .projectModal {
        max-width: 100%;
    }
}
