.header {
  width: 100%;
  align-items: center;
  position: fixed;
  padding: 20px 0;
  z-index: 999;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
  background: #f5f5f5;
}

.box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
}

.mobile_btn {
  display: none;
}

@media (max-width: 800px) {
  .mobile_btn {
    display: block;
    position: absolute;
    right: 10px;
    top: 25px;
    cursor: pointer;
    z-index: 10;
  }

  .header {
    padding: 22px 0;
  }
}
