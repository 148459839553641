.footerNavList {
  display: flex;
  justify-content: center;
  gap: 62px;
}

.footerNavList li a {
  width: 100px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

@media (max-width: 837px) {
  .footerNavList {
    gap: 28px;
  }
}

@media (max-width: 800px) {
  .footerNavList {
    display: flex;
    justify-content: center;
    gap: 2px;
  }

  .footerNavList li a {
    font-size: 14px;
  }
}
@media (max-width: 800px) {
  .footerNavList {
    flex-direction: column;
    align-items: center;
  }
}
