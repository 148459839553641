.card {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 18px;
    padding: 32px 23px 40px 23px;
    text-align: center;
    background: #FFFFFF;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    width: 448px;
}

.cardTitle {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
}

.cardText {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 115%;
    color: #000000;
}

@media screen and (max-width: 768px) {
    .card {
        width: 328px;
    }
}

