.projectContainer {
  position: relative;
  cursor: pointer;
}

.projectImage {
  width: 100%;
  height: auto;
}

.projectHovering {
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
  justify-content: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.65);
  padding: 20px;
  color: #fff;
  transition: opacity 0.5s ease;
}

.projectContainer:hover .photo {
  opacity: 0.7;
}

.projectContainer:hover .projectHovering {
  opacity: 1;
}

.projectTitle {
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
}
.projectText {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
}
