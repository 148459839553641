.reviewCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 32px 22px 40px 22px;
    background: #EBE7E2;
    /*border-radius: 6px;*/
    width: 328px;
}
.reviewCardName {
    margin-bottom: 6px;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: #000000;
}
.reviewCardData {
    margin-bottom: 12px;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #000000;
}
.reviewCardLine {
    margin-bottom: 12px;
    height: 1px;
    background-color: #FF4F00;
    width: 100%;
}
.reviewCardText {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #000000;
    max-width: 285px;
}

.reviewCardAvatar {
    border-radius: 50%!important;
    margin-bottom: 12px;
}