.contactInfoTextContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 32px;
  width: 292px;
}

.contactInfoTextContainer a,
.contactInfoTextContainer p {
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
}

.contactInfoSchedule {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 32px;
}

.contactInfoScheduleTitle {
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
  margin-bottom: 2px;
}

.contactInfoScheduleText {
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
}

@media screen and (max-width: 800px) {
  .contactInfoTextContainer p,
  .contactInfoTextContainer a,
  .contactInfoScheduleTitle,
  .contactInfoScheduleText {
    font-size: 16px;
  }
}
