.aboutProjectContainer {
    display: flex;
    gap: 32px;
    justify-content: center;
}

.aboutProjectTitle {
    margin-top: 60px;
    margin-bottom: 8px;
    font-weight: 500;
    font-size: 44px;
    line-height: 58px;
    color: #000000;
}
.aboutProjectText {
    font-weight: 400;
    font-size: 30px;
    line-height: 39px;
    color: #000000;
}

.aboutProjectLine {
    width: 100%;
    height: 1px;
    background-color: rgba(58, 62, 62, 0.2);
    margin: 18px 0;
}

.aboutProjectImagesList {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 32px;
    margin-top: 32px;
}

.aboutProjectInfo {
    width: 448px;
}

@media screen and (max-width: 800px) {
    .aboutProjectContainer {
        flex-direction: column;
    }

    .aboutProjectInfo {
        text-align: center;
        width: 100%;
    }

    .aboutProjectImageContainer {
        text-align: center;
    }

    .aboutProjectTitle {
        margin-top: 0;
    }
}



