.footer {
  padding: 40px 0;
  background: #3a3e3e;
  margin-top: auto;
}

.footerLogo {
  font-weight: 400;
  font-size: 18px;
  color: white;
}

.footerSocialInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footerCopyRight p {
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: rgba(255, 255, 255, 0.5);
}

.footerInfo ul {
  display: flex;
  flex-direction: column;
  gap: 20px;
  color: #fff;
}

.footerInfo {
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
  text-align: right;
}

.footerInfo ul li a {
  color: #d4d4d4;
}

.line {
  height: 1px;
  width: 100%;
  border: 1px solid rgba(255, 255, 255, 0.5);
  margin: 24px 0;
}

.footerLinkAuthor {
  text-align: center;
  margin-top: 20px;
}

.footerLinkAuthor a {
  color: rgba(212, 212, 212, 0.5);
  font-size: 13px;
}

.footerLinkAuthor a:hover {
  color: rgba(212, 212, 212, 0.5) !important;
}

@media (max-width: 837px) {
  .footerAddress {
    width: 221px;
  }
}

@media (max-width: 800px) {
  .footerSocialInfo {
    justify-content: space-between;
    align-items: center;
  }

  .footerInfo {
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 24px;
  }

  .footerInfo ul li a {
    font-size: 12px;
  }

  .footerLogo {
    font-weight: 400;
    font-size: 18px;
  }
}
