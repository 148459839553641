.reviewsModalButton {
  display: inline-block;
  width: 254px;
  height: 56px;
  background: #ff4f00;
  color: white;
  transition: 0.3s all;
  border: 1px solid #ff4f00;
  text-align: center;
  line-height: 56px;
  font-weight: 500;
  font-size: 18px;
}

.reviewsModalButton:hover {
  color: #ff4f00;
  background: #f5f5f5;
}

.reviewsModalButtonText {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
}

.reviewsModalContainer {
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 800px) {
  .reviewsModalContainer {
    margin-top: 40px;
  }
}

.reviewsModal {
  margin-top: 30px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 30px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
  max-width: 568px;
  width: 100%;
}

.formLabel {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #ff4f00;
  margin-bottom: 22px;
  display: flex;
  flex-direction: column;
  gap: 2px;
}
.formError {
  color: red;
  font-size: 16px;
  margin-top: 5px;
}

.formInput {
  padding: 12px 16px;
  border: 1px solid rgba(58, 62, 62, 0.5);
  margin-top: 2px;
  border-radius: 0;
  color: #000;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 2px;
  width: 100%;
  margin: 0 auto;
}

.formInputFile {
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  cursor: pointer;
}

.fileInputContainer {
  position: relative;
  display: inline-block;
}
.fileInputLabel {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  background-color: #E4E4E4;
  padding: 10px;
  font-size: 16px;
  font-weight: 500;
  color: #000;
  cursor: pointer;
}

.formMessage {
  border: 1px solid rgba(58, 62, 62, 0.5);
  margin-top: 2px;
  color: #000;
  padding: 12px 16px;
  border-radius: 0;
}

.submitButton {
  width: 254px;
  height: 50px;
  color: white;
  background-color: #ff4f00 !important;
  transition: 0.3s all;
  border: 1px solid #ff4f00;
  align-items: center;
  display: flex;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  margin-top: 60px;
  border-radius: 0;
}

.submitButtonContainer {
  display: flex;
  justify-content: center;
}

.reviewsModalCrossButton {
  display: flex;
  justify-content: flex-end;
}

.reviewsModalDoneText {
  font-weight: 600;
  font-size: 44px;
  line-height: 58px;
  text-align: center;
  color: #000000;
}

.reviewsModalDoneContainer {
  display: flex;
  flex-direction: column;
  gap: 18px;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.submitButton:hover {
  color: #ff4f00;
  background-color: #f5f5f5 !important;
}

.reviewsModalWarningMessage {
  margin-top: 6px;
  font-size: 14px;
}

@media screen and (max-width: 837px){
  .reviewsModalButton, .submitButton {
    width: 208px
  }
}

@media screen and (max-width: 800px){
  .reviewsModalButtonText {
    font-size: 16px;
  }
}

@media screen and (max-width: 660px) {
  .reviewsModalButton, .submitButton {
    width: 156px;
    height: 50px
  }

  .submitButton {
    margin-top: 20px;
  }

  .reviewsModal {
    width: 350px;
    padding: 10px;
  }

  .formLabel {
    margin-bottom: 12px;
  }

  .reviewsModalDoneContainer {
    gap: 10px;
  }
  .reviewsModalDoneText {
    font-size: 32px;
  }

}