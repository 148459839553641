.contact-page-content {
  display: flex;
  justify-content: space-between;
  gap: 32px;
}

.container-margin {
  margin-top: 160px;
  margin-bottom: 100px;
}

.not-found-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 60px;
}

.about-page-container-margin {
  margin-bottom: 80px;
}

@media screen and (max-width: 800px) {
  .container-margin {
    margin-top: 140px;
    margin-bottom: 80px;
  }

  .about-page-container-margin {
    margin-bottom: 60px;
  }

  .contact-page-content {
    justify-content: center;
    flex-direction: column-reverse;
    gap: 40px;
  }
}
