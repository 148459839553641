.aboutUsInfoContainer {
  width: 100%;
  padding-top: 160px;
  padding-bottom: 42px;
  background-color: #4d4d4d;
  background-repeat: no-repeat;
  background-size: cover;
}

.aboutUsInfoTextContainer {
  display: flex;
  gap: 32px;
}

.aboutUsInfoText {
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  width: 700px;
  margin: 0 auto;
  color: #fff;
}

@media screen and (max-width: 750px) {
  .aboutUsInfoText {
    width: 100%;
  }
}

@media screen and (max-width: 800px) {
  .aboutUsInfoTextContainer {
    flex-direction: column;
    gap: 16px;
  }

  .aboutUsInfoContainer {
    padding-top: 140px;
  }
}
