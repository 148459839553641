.socialIconsList {
    display: flex;
    gap: 26px;
}

.socialIconsList li a svg path {
    transition: .3s all;
}

ul.socialIconsList li:hover path {
    fill: #ff4f00 ;
}