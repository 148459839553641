.ourProjectContainer {
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    text-align: center;
}
.ourProjectList {
    display: flex;
    gap: 32px;
    justify-content: center;
    margin-bottom: 60px;
}

@media (max-width: 800px) {
    .ourProjectList {
        flex-direction: column;
    }

    .ourProjectContainer {
        margin-top: 80px;
    }
}