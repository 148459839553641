.scrollToTop {
    position: fixed;
    bottom: 20px;
    right: 20px;
    display: none;
    transition: opacity 0.2s;
    border: none;
    cursor: pointer;
    z-index: 100;
}

.visible {
    display: block;
    opacity: 0.8;
}

.scrollToTop:hover {
    opacity: 1;
}