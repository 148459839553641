.welcomeToUsWrapper {
    background: #EBE7E2;
    width: 100%;
    padding: 100px 0 42px 0;
    text-align: center;
}
.welcomeToUsTextContainer {
    display: flex;
    gap: 32px;
    margin-bottom: 60px;
    justify-content: center;
}
.welcomeToUsText {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: #000000;
    width: 688px;
}

@media (max-width: 800px) {
    .welcomeToUsTextContainer {
        display: flex;
        justify-content: center;
        flex-direction: column;
    }

    .welcomeToUsText {
        width: 100%;
        font-size: 16px;
    }

    .welcomeToUsWrapper {
        padding: 80px 0 42px 0;
    }
}