.portfolioList {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 32px;
    justify-content: center;
    align-items: center;
}

.portfolioContainer {
    margin-top: 160px;
    margin-bottom: 100px;
}

@media screen and (max-width: 1100px) {
    .portfolioList {
        grid-template-columns: repeat(2, 1fr);
        gap: 16px;
    }
}

@media screen and (max-width: 800px) {
    .portfolioList {
        grid-template-columns: repeat(1, 1fr);
    }

    .portfolioContainer {
        margin-top: 140px;
        margin-bottom: 100px;
    }
}
